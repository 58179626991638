













import { Component, Vue } from 'vue-property-decorator';
import AnimaArticle from '@/components/AnimaArticle.vue';

const organizatorsList = [
  {
    src: require('@/assets/organizators/anima.png'),
    srcset: `${require('@/assets/organizators/anima@2x.png')} 2x`,
  },
  {
    src: require('@/assets/organizators/logo-scuoledi-roma.png'),
    srcset: `${require('@/assets/organizators/logo-scuoledi-roma@2x.png')} 2x`,
  },
  {
    src: require('@/assets/organizators/re-nali-art.png'),
    srcset: `${require('@/assets/organizators/re-nali-art@2x.png')} 2x`,
  },
];
const partnersList = [
  {
    src: require('@/assets/partners/maeci-ambasciata-italia-o-it-01-41.png'),
    srcset: `${require('@/assets/partners/maeci-ambasciata-italia-o-it-01-41@2x.png')} 2x`,
  },
  {
    src: require('@/assets/partners/logo-ambasciata-sfondo-trasparente-1.png'),
    srcset: `${require('@/assets/partners/logo-ambasciata-sfondo-trasparente-1@2x.png')} 2x`,
  },
  {
    src: require('@/assets/partners/mos-dep-logo-ru-1.png'),
    srcset: `${require('@/assets/partners/mos-dep-logo-ru-1@2x.png')} 2x`,
  },
  {
    src: require('@/assets/partners/iic-vector-mosca.png'),
    srcset: `${require('@/assets/partners/iic-vector-mosca@2x.png')} 2x`,
  },
  {
    src: require('@/assets/partners/rossotrudnicestvo.png'),
    srcset: `${require('@/assets/partners/rossotrudnicestvo@2x.png')} 2x`,
  },
  {
    src: require('@/assets/partners/mos-dep-logo-ru-2.png'),
    srcset: `${require('@/assets/partners/mos-dep-logo-ru-2@2x.png')} 2x`,
  },
  {
    src: require('@/assets/partners/roma.png'),
    srcset: `${require('@/assets/partners/roma@2x.png')} 2x`,
  },
  {
    src: require('@/assets/partners/image-001.png'),
    srcset: `${require('@/assets/partners/image-001@2x.png')} 2x`,
  },
];
const sponsorsList = [
  {
    src: require('@/assets/sponsors/fila.png'),
    srcset: `${require('@/assets/sponsors/fila@2x.png')} 2x`,
  },
  {
    src: require('@/assets/sponsors/logo-nefis.png'),
    srcset: `${require('@/assets/sponsors/logo-nefis@2x.png')} 2x`,
  },
  {
    src: require('@/assets/sponsors/rus-ita-logo-2019-final-2-2-4.png'),
    srcset: `${require('@/assets/sponsors/rus-ita-logo-2019-final-2-2-4@2x.png')} 2x`,
  },
  {
    src: require('@/assets/sponsors/trademark-maimeri.png'),
    srcset: `${require('@/assets/sponsors/trademark-maimeri@2x.png')} 2x`,
  },
  {
    src: require('@/assets/sponsors/logo-gi.png'),
    srcset: `${require('@/assets/sponsors/logo-gi@2x.png')} 2x`,
  },
  {
    src: require('@/assets/sponsors/ferrero-corporate-brown.png'),
    srcset: `${require('@/assets/sponsors/ferrero-corporate-brown@2x.png')} 2x`,
  },
];

@Component({
  components: {
    AnimaArticle,
  },
})
export default class Partners extends Vue {
  get groups() {
    // TODO в следующем году переделать как сделано в Жюри
    const organizators = {
      title: this.$t('Partners.organizators'),
      value: organizatorsList,
    };
    const partners = {
      title: this.$t('Partners.partners'),
      value: partnersList,
    };
    const sponsors = {
      title: this.$t('Partners.sponsors'),
      value: sponsorsList,
    };
    return [organizators, partners, sponsors];
  }
}
